<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-padding" color="primary">
            <div class="display-flex ion-align-items-center">
                <app-icon name="info" size="large" colour="secondary"></app-icon>
                <span class="margin-left-l">Supervisor</span>
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <app-field-list classList="margin-top-0">
        <ion-row>
            <ion-col size="6">
                <app-field-value labelSize="6" valSize="6" label="Supervisor ID">
                    {{ advisor.id | default }}
                </app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Supervisor Name">
                    {{ advisor.name | default }}
                </app-field-value>
            </ion-col>
            <ion-col size="5" offset="1">
                <app-field-value labelSize="6" valSize="6" label="Workload">
                    {{ advisor.percentage | default }}%
                </app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Advisor Role">
                    {{ advisor.advisorRole?.description | default }}
                </app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Haplo Role">
                    {{ advisor.haploRole?.description | default }}
                </app-field-value>                
            </ion-col>
        </ion-row>
    </app-field-list>
    <hr />

    <app-field-list>
        <app-field-value labelSize="5" valSize="7" label="Programme">
            {{ research.programme.description | default }}
        </app-field-value>
    </app-field-list>

    <app-field-list>
        <app-field-value labelSize="5" valSize="7" label="Plans">
            <ng-container *ngIf="plans?.length > 0; else noPlans">
                <ng-container *ngFor="let item of plans">
                    {{ item.plan.description }} ({{ item.plan.code }})<br/>
                </ng-container>
            </ng-container>
            <ng-template #noPlans>
                No data
            </ng-template>
        </app-field-value>
    </app-field-list>
</ion-content>