<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-padding" color="primary">Milestones</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <app-programme-milestone-table [milestones]="milestones" />
    </app-dataload-spinner>

    <div *ngIf="!isLoading && milestones?.length === 0">
        <p class="header__notfound margin-0">No data to display</p>
    </div>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</ion-content>