<div [class]="highlight ? 'row-white':'row-grey'">
    <ion-grid class="ion-no-padding">
        <ion-row class="ion-padding-start ion-padding-end" [ngClass]="!highlight ? 'row-white':'row-grey'">
            <ion-col size="8">
                <app-field-list classList="ion-padding-top margin-0">
                    <app-field-value label="Application No">
                        <a title="View application in CS9" [href]="applicationLink" target="_blank">{{ application.applicationNumber | default }}</a>
                    </app-field-value>
                </app-field-list>
            </ion-col>
        </ion-row>
        <ion-row class="ion-padding-start ion-padding-end">
            <ion-col size="8" class="application-details">
                <app-field-list>
                    <app-field-value label="Academic Plan"> <ng-container *ngFor="let plan of application.plans">
                            <span class="ion-no-margin">{{ plan.description | default }}</span>
                            <span class="ion-no-margin" *ngIf="plan.type.description">
                                - {{ plan.type.description }}
                            </span>
                            <br />
                        </ng-container>
                    </app-field-value>

                    <app-field-value label="Admit Term">
                        {{ application.term.code | default }}
                    </app-field-value>

                    <app-field-value label="Admit Type">
                        {{ application.admitType.code | default }}
                        <span *ngIf="application.admitType.description">({{ application.admitType.description }})</span>
                    </app-field-value>

                    <app-field-value label="Prog. Action">
                        {{ application.programme?.reason.action?.description | default }}
                    </app-field-value>

                    <app-field-value label="Prog. Reason">
                        {{ application.programme?.reason.description | default }}
                    </app-field-value>

                    <app-field-value label="App. Status Date">
                        {{ application.lastUpdated | shortDate | default }}
                    </app-field-value>

                    <app-field-value label="Offer Expiry Date">
                        {{ application.offerExpiry | shortDate | default }}
                    </app-field-value>

                    <app-field-value label="Prog. App. Closing Date">
                        {{ application.closingOn | shortDate | default }}
                    </app-field-value>
                </app-field-list>

                <hr class="ion-no-margin background-medium" />

                <app-field-list>
                    <app-field-value label="GPE" *ngIf="isGradePointEquivalentVisble">{{ application.gradePointEquivalent | default }}</app-field-value>
                    <app-field-value label="Academic Load">
                        {{ application.academicLoad?.description | default }}
                    </app-field-value>
                    <app-field-value label="Campus">{{ application.campus?.description | default }}</app-field-value>
                </app-field-list>

                <hr class="ion-no-margin background-medium" />

                <app-field-list>
                    <ng-container *ngIf="application.agencies?.length > 0; else noAgency">
                        <ng-container *ngFor="let agency of application.agencies">
                            <app-field-value label="Agent Name">
                                <span *ngIf="agency.firstName">{{ agency.firstName }}</span>&nbsp;<span *ngIf="agency.lastName">{{ agency.lastName }}</span>
                            </app-field-value>
                            <app-field-value label="Agent Type">
                                {{ agency.group.description | default }}
                            </app-field-value>
                            <app-field-value label="Agent Email">
                                <ng-container *ngFor="let email of agency.emailAddresses">
                                    <span>{{ email.email }}</span><br/>
                                </ng-container>
                            </app-field-value>
                            <app-field-value label="Agency">{{ agency.name | default }}</app-field-value>

                        </ng-container>
                    </ng-container>
                    <ng-template #noAgency>
                        <ion-row>
                            <ion-col size="12" class="none-found">
                                No data display for Agent Information
                            </ion-col>
                        </ion-row>
                    </ng-template>
                </app-field-list>

                <hr class="ion-no-margin background-medium" />

                <app-field-list>
                    <ng-container *ngIf="application.sponsors?.length > 0; else noSponsor">
                        <ng-container *ngFor="let sponsor of application.sponsors">
                            <app-field-value label="Sponsor">
                                {{ sponsor.description | default }}
                            </app-field-value>
                            <app-field-value label="Term">
                                {{ sponsor.startTerm.description | default }}
                            </app-field-value>
                            <app-field-value label="Academic Year">
                                {{ sponsor.startTerm.academicYear | default }}
                            </app-field-value>
                            <app-field-value label="Verified">
                                {{ sponsor.verified | yesNo | default }}
                            </app-field-value>
                        </ng-container>
                    </ng-container>
                    <ng-template #noSponsor>
                        <ion-row>
                            <ion-col size="12" class="none-found">
                                No data display for Sponsor Information
                            </ion-col>
                        </ion-row>
                    </ng-template>
                </app-field-list>

                <hr class="ion-no-margin background-medium" />

                <app-field-list>
                    <div class="display-flex">
                        <ion-button class="margin-right-l" size="large" (click)="openReferralModal()">
                            Assessment Referral Info
                        </ion-button>
                        <ion-button class="margin-right-l" size="large" (click)="openIntendedCoursesModal()">
                            Intended Courses
                        </ion-button>
                        <ion-button *ngIf="isConditionalOffer || isAdmitOffer" size="large" (click)="openOfferInfoModal()">
                            Additional Offer Information
                        </ion-button>
                        <ion-button size="large" (click)="openApplicationQuestionsModal()">
                            Application Questions
                        </ion-button>
                    </div>
                </app-field-list>
            </ion-col>
            <ion-col size="4">
                <div class="action-box ats-action margin-top-l margin-bottom-l margin-left-l" *ngIf="isAtsVisible">
                    <div class="container">
                        <h3 class="ion-no-margin">ATS Status</h3>
                        <p *ngIf="application.statusMessage" [innerHtml]="application.statusMessage"></p>
                        <p *ngIf="!application.statusMessage">No status available</p>
                        <ion-button size="large" (click)="openAtsLink(application.externalApplicationNumber)"
                            *ngIf="application.externalApplicationNumber">
                            View ATS Summary
                        </ion-button>
                    </div>
                </div>

                <div class="action-box fasttrack-action margin-top-l margin-bottom-l margin-left-l" [ngClass]="!highlight ? 'row-white':'row-grey'"
                    *ngIf="application.fasttrack">
                    <div class="container">
                        <h3 class="ion-no-margin ion-padding-bottom">Fast Track Status</h3>
                        <ion-grid class="ion-no-margin">
                            <ion-row>
                                <ion-col size="4" size-md="auto"><span class="fasttrack-action__label">Action:</span></ion-col>
                                <ion-col size="8" size-md="" class="ion-text-start ion-padding-start">
                                    {{ application.fasttrack.action.description }}
                                </ion-col>
                            </ion-row>

                            <ion-row>
                                <ion-col size="4" size-md="auto"><span class="fasttrack-action__label">Reason:</span></ion-col>
                                <ion-col size="8" size-md="" class="ion-text-start ion-padding-start">
                                    {{ application.fasttrack.reason.description }}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>

                <div class="action-box deferred-application margin-top-l margin-bottom-l margin-left-l" [ngClass]="!highlight ? 'row-white':'row-grey'"
                    *ngIf="application?.deferred">
                    <div class="container">
                        <h3 class="ion-no-margin ion-padding-bottom">App Deferred To</h3>
                        <ion-grid class="ion-no-margin">
                            <ion-row>
                                <ion-col size="6" class="ion-text-left">
                                    <span class="bold">New App No.</span>
                                </ion-col>
                                <ion-col offset="1" size="5" class="ion-text-left">
                                    {{ application.deferred.applicationNumber | default }}
                                </ion-col>
                            </ion-row>

                            <ion-row>
                                <ion-col size="6" class="ion-text-left">
                                    <span class="bold">New Admit Term</span>
                                </ion-col>
                                <ion-col offset="1" size="5" class="ion-text-left">
                                    {{ application.deferred.term.description | default }}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>

                <div class="action-box deferred-application margin-top-l margin-bottom-l margin-left-l" [ngClass]="!highlight ? 'row-white':'row-grey'"
                    *ngIf="application?.defers">
                    <div class="container">
                        <h3 class="ion-no-margin ion-padding-bottom">App Deferred From</h3>
                        <ion-grid class="ion-no-margin">
                            <ion-row>
                                <ion-col size="6" class="ion-text-left">
                                    <span class="bold">Original App No.</span>
                                </ion-col>
                                <ion-col offset="1" size="5" class="ion-text-left">
                                    {{ application.defers.applicationNumber | default }}
                                </ion-col>
                            </ion-row>

                            <ion-row>
                                <ion-col size="6" class="ion-text-left">
                                    <span class="bold">Original Admit Term</span>
                                </ion-col>
                                <ion-col offset="1" size="5" class="ion-text-left">
                                    {{ application.defers.term.description | default }}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>

                <div class="action-box deferred-application margin-top-l margin-bottom-l margin-left-l" [ngClass]="!highlight ? 'row-white':'row-grey'"
                    *ngIf="application?.research">
                    <div class="container">
                        <h3 class="ion-no-margin ion-padding-bottom">Doctoral Application Status</h3>
                        <ion-button size="large" (click)="openResearchAppModal()">
                            See Details
                        </ion-button>
                    </div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>