import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Milestone } from '@app/domain/models';

@Component({
  selector: 'app-programme-milestone-table',
  templateUrl: './milestone-table.component.html',
  styleUrl: './milestone-table.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class MilestoneTableComponent {
    @Input() milestones: Milestone[];

    milestoneDetailsOpen = new Set<number>();

    constructor() {
    }

    handleToggleDetails(index: number) {
      if (!this.milestoneDetailsOpen.has(index)) {
          this.milestoneDetailsOpen.add(index);
      } else {
          this.milestoneDetailsOpen.delete(index);
      }
  }

  isShowDetails(index: number) {
    return this.milestoneDetailsOpen.has(index) ?? false;
  }
}
