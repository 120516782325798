<div [class]="highlight ? 'row-white':'row-grey'">
    <app-field-list classList="margin-top-0">
        <app-field-value label="Session">{{ insurance?.session?.description | default }}</app-field-value>
    </app-field-list>
    <app-field-list classList="margin-0">
        <app-field-value label="Cover Start">{{ insurance.coverStart | shortDate | default }}</app-field-value>
        <app-field-value label="Cover End">{{ insurance.coverEnd | shortDate | default }}</app-field-value>
        <app-field-value label="Months Cover">{{ insurance.monthsOfCover | default }}</app-field-value>

        <app-field-value label="Coverage type">{{ insurance?.coverageType?.description | default }}</app-field-value>
        <app-field-value label="Provider">{{ insurance?.provider?.description | default }}</app-field-value>
        <app-field-value label="Plan">{{ insurance?.coverPlan?.description | default }}</app-field-value>

        <app-field-value *ngIf="insurance?.cancellationReason" label="Cancellation reason">
            {{ insurance?.cancellationReason?.description | default }}
        </app-field-value>
    </app-field-list>
</div>
