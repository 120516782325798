import { Configuration } from '@domain/models/app-configuration';
import { CS9Paths, ResourcePaths, Scopes } from './common';

export const environment: Configuration = {
  production: false,
  version: '1.0.0-#377',
  auth: {
    cognitoAwsRegion: 'ap-southeast-2',
    cognitoUserPoolId: 'ap-southeast-2_KgOa2ZUxp',
    cognitoDomain: 'uoapool-mfa-nonprod',
    cognitoClientId: '61fpotjopgm633018ttfbedn9k',
    redirectUri: 'https://customerprofile.dev.auckland.ac.nz',
    logoutUri: '',
    scopes: Scopes.join(' '),
    codeChallengeMethod: 'S256',
  },
  googleTagManager: {
    id: 'GTM-5DQHMX7'
  },
  privateUrlKeyWords: {
    whoNeedBearerToken: [{ url: 'apis.dev.auckland.ac.nz/person-360/v1', optional: false }],
  },
  api: {
    baseUrl: 'https://apis.dev.auckland.ac.nz/person-360/v1/',
    paths: ResourcePaths
  },
  ui: {
    showUnavailableMenus: true,
  },
  links: {
    epr: 'https://iam.dev.auckland.ac.nz',
    studentDocs: 'https://studentdocs.test.auckland.ac.nz/saimages/index.html',
    maps: 'https://maps.auckland.ac.nz/room',
    ats: 'https://applytostudy.dev.auckland.ac.nz/support-staff',
    studentDocuments: 'https://saimageswebtest.auckland.ac.nz'
  },
  cs9: {
    baseUrl: 'https://www.student.admin.tst.auckland.ac.nz',
    paths: CS9Paths
  }
};
