import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {ThesisSubmission, ThesisEvent, ThesisExaminer, ThesisFormat} from '@domain/models';

@Component({
  selector: 'app-thesis-submission-details',
  templateUrl: './thesis-submission-details.component.html',
  styleUrl: './thesis-submission-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThesisSubmissionDetailsComponent {
  @Input() submission: ThesisSubmission;
  @Input() highlight: boolean;

  get examiners(): ThesisExaminer[] {
    return this.submission.examiners;
  }

  get formats(): ThesisFormat[] {
    return this.submission.formats;
  }

  get events(): ThesisEvent[] {
    return this.submission.events;
  }
}
