import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/providers';
import { Observable, catchError, map, of } from 'rxjs';
import { BypassErrorService } from '@uoa/error-pages';
import { BaseHttpService } from './base-http-service';
import { cacheable } from '@core/cache';
import {
    Visa,
    AppConfiguration,
    Checklist,
    ServiceIndicator,
    ExternalTest,
    EntranceQualification,
    EducationBackground,
    SecondaryEducation,
    TertiaryEducation,
    EnglishLanguageEducation,
    UnsubmittedApplications,
    SubmittedApplication,
    FoundationEducation,
    Residency,
    Insurance,
    Group,
    Comment,
    AELR,
    AcademicStanding,
    PriorityGroup,
    ResearchApplication,
    StudyLink,
    StudyLinkLoan,
    TuitionFees,
    Concession,
    Delna,
    StudentProgramme,
    Course,
    ThirdPartyContract,
    Transcript,
    Progress,
    ExternalTestResult,
    Communication,
    Timetable,
    TermProgress,
    Exam,
    ApplicationReferral,
    TaxInvoice,
    Graduation,
    Research,
    Degree,
    IntendedCourse,
    StudentCourseCredit,
    EnrolmentCart,
    ScholarshipPayment,
    ScholarshipAwarded,
    ScholarshipApplication,
    ScholarshipApplicationDetail,
    StudentApplicationQuestion,
    ThesisSubmission, Milestone
} from '@domain/models';
import { DataResponse } from './model';

/**
 * Get student (or potential student) information from application, to enrolment to matriculation.
 *
 */
@Injectable({
    providedIn: 'root',
})
export class StudentService extends BaseHttpService {
    constructor(
        @Inject(APP_CONFIG) configuration: AppConfiguration,
        http: HttpClient,
        errorBypass: BypassErrorService) {
            super(configuration, http, errorBypass);
    }

    /**
     * Get a list of student visas for each application period
     *
     * @param id
     *
     * @returns
     */
    @cacheable()
    public getVisas(id: string): Observable<Visa[]> {
        return this.getResource<DataResponse<Visa>>('visas', {params: {id}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getChecklists(id: string): Observable<Checklist[]> {
        return this.getResource<DataResponse<Checklist>>('checklist', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data)
            );
    }

    @cacheable()
    public getServiceIndicators(id: string): Observable<ServiceIndicator[]> {
        return this.getResource<DataResponse<ServiceIndicator>>('serviceIndicators', {params: {id}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getExternalTests(id: string): Observable<ExternalTest[]> {
        return this.getResource<DataResponse<ExternalTest>>('externalTests', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getExternalTestResults(id: string, testId: string): Observable<ExternalTestResult[]> {
        return this.getResource<DataResponse<ExternalTestResult>>('externalTestResults', {params: {id, testId}, handleError: true})
            .pipe(
                map(result => result.data)
            );
    }

    @cacheable()
    public getComments(id: string): Observable<Comment[]> {
        return this.getResource<DataResponse<Comment>>('comments', {params: {id}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getEntranceEducation(id: string): Observable<EntranceQualification> {
        return this.getResource<EntranceQualification>('educationEntrance', {params: {id}, handleError: true})
        .pipe(
            catchError(this.returnNullIf404Error<EntranceQualification>)
        );
    }

    @cacheable()
    public getEducationBackground(id: string): Observable<EducationBackground> {
        return this.getResource<EducationBackground>('educationBackground', {params: {id}, handleError: true})
        .pipe(
            catchError(this.returnNullIf404Error<EducationBackground>)
        );
    }

    @cacheable()
    public getSecondaryEducation(id: string): Observable<SecondaryEducation[]> {
        return this.getResource<DataResponse<SecondaryEducation>>('educationSecondary', {params: {id}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getTertiaryEducation(id: string): Observable<TertiaryEducation[]> {
        return this.getResource<DataResponse<TertiaryEducation>>('educationTertiary', {params: {id}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getFoundationEducation(id: string): Observable<FoundationEducation[]> {
        return this.getResource<DataResponse<FoundationEducation>>('educationFoundation', {params: {id}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getEnglishEducation(id: string): Observable<EnglishLanguageEducation> {
        return this.getResource<EnglishLanguageEducation>('educationEnglish', {params: {id}, handleError: true})
        .pipe(
            catchError(this.returnNullIf404Error<EnglishLanguageEducation>)
        );
    }

    @cacheable()
    public getUnsubmittedApplications(id: string): Observable<UnsubmittedApplications> {
        return this.getResource<UnsubmittedApplications>('unsubmittedApplications', {params: {id}, handleError: true})
        .pipe(
            catchError(this.returnNullIf404Error<UnsubmittedApplications>)
        );
    }

    @cacheable()
    public getSubmittedApplications(id: string): Observable<SubmittedApplication[]> {
        return this.getResource<DataResponse<SubmittedApplication>>('submittedApplications', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getResidency(id: string): Observable<Residency[]> {
        return this.getResource<DataResponse<Residency>>('residency', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getInsurance(id: string): Observable<Insurance[]> {
        return this.getResource<DataResponse<Insurance>>('insurance', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getGroups(id: string): Observable<Group[]> {
        return this.getResource<DataResponse<Group>>('groups', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getAELR(id: string): Observable<AELR[]> {
        return this.getResource<DataResponse<AELR>>('aelr', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getDelna(id: string): Observable<Delna[]> {
        return this.getResource<DataResponse<Delna>>('delna', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getAcademicStanding(id: string): Observable<AcademicStanding[]> {
        return this.getResource<DataResponse<AcademicStanding>>('academicStanding', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getPriorityGroups(id: string): Observable<PriorityGroup[]> {
        return this.getResource<DataResponse<PriorityGroup>>('priorityGroups', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getResearchApplications(id: string): Observable<ResearchApplication[]> {
        return this.getResource<DataResponse<ResearchApplication>>('researchApplications', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getStudyLink(id: string): Observable<StudyLink[]> {
        return this.getResource<DataResponse<StudyLink>>('studyLink', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getStudyLinkLoans(id: string): Observable<StudyLinkLoan[]> {
        return this.getResource<DataResponse<StudyLinkLoan>>('studyLinkLoans', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getStudentTuitionFees(id: string): Observable<TuitionFees> {
        return this.getResource<TuitionFees>('tuitionFees', {params: {id}, handleError: true})
            .pipe(
                map(result => result),
            );
    }

    @cacheable()
    public getStudentConcessions(id: string): Observable<Concession[]> {
        return this.getResource<DataResponse<Concession>>('concessions', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getStudentProgrammes(id: string): Observable<StudentProgramme[]> {
        return this.getResource<DataResponse<StudentProgramme>>('programmes', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getCourses(id: string, programme?: string): Observable<Course[]> {
        const options = {
            params: {id},
            handleError: true
        };

        if (programme) {
           options['query'] = { programme };
        }

        return this.getResource<DataResponse<Course>>('enrolments', options)
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getThirdPartyContracts(id: string): Observable<ThirdPartyContract[]> {
        return this.getResource<DataResponse<ThirdPartyContract>>('thirdPartyContracts', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data)
            );
    }

    @cacheable()
    public getStudentProgress(id: string): Observable<Progress[]> {
        return this.getResource<DataResponse<Progress>>('progress', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getTranscripts(id: string): Observable<Transcript[]> {
        return this.getResource<DataResponse<Transcript>>('transcripts', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getCommunications(id: string): Observable<DataResponse<Communication>> {
        return this.getResource<DataResponse<Communication>>('communications', {params: {id}, query: {start: '1900-01-01', limit: 50}, handleError: true});
    }

    @cacheable()
    public getCommunicationsByUrl(url: string): Observable<DataResponse<Communication>> {
        return this.getResourceByUrl<DataResponse<Communication>>(url, true);
    }

    @cacheable()
    public getTimetables(id: string, start?: number, end?: number): Observable<Timetable[]> {
        return this.getResource<DataResponse<Timetable>>('timetables', {params: {id}, handleError: true, query: {start, end}})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getTermProgress(id: string): Observable<TermProgress[]> {
        return this.getResource<DataResponse<TermProgress>>('termProgress', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getExams(id: string): Observable<Exam> {
        return this.getResource<Exam>('exams', {params: {id}, handleError: true})
            .pipe(
                map(result => result),
            );
    }

    @cacheable()
    public getApplicationReferrals(id: string, appNo: string): Observable<ApplicationReferral> {
        return this.getResource<ApplicationReferral>('referrals', {params: {id, appNo}, handleError: true})
            .pipe(
                map(result => result),
                catchError(this.returnNullIf404Error<ApplicationReferral>)
            );
    }

    @cacheable()
    public getIntendedCourses(id: string, appNo: string): Observable<IntendedCourse[]>{
        return this.getResource<DataResponse<IntendedCourse>>('intendedCourses', { params: { id, appNo }, handleError: true }).pipe(
          map((result) => result.data)
        );
    }

    @cacheable()
    public getInvoices(id: string): Observable<TaxInvoice> {
        return this.getResource<TaxInvoice>('invoices', {params: {id}, handleError: true})
            .pipe(
                map(result => result)
            );
    }

    @cacheable()
    public getGraduationInfo(id: string): Observable<Graduation[]> {
        return this.getResource<DataResponse<Graduation>>('graduations', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getResearchInfo(id: string): Observable<Research[]> {
        return this.getResource<DataResponse<Research>>('research', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getDegrees(id: string): Observable<Degree[]> {
        return this.getResource<DataResponse<Degree>>('degrees', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getCourseCredit(id: string): Observable<StudentCourseCredit[]> {
        return this.getResource<DataResponse<StudentCourseCredit>>('credit', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getEnrolmentCart(id: string): Observable<EnrolmentCart[]> {
        return this.getResource<DataResponse<EnrolmentCart>>('enrolmentCart', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getScholarshipsAwarded(id: string): Observable<ScholarshipAwarded[]> {
        return this.getResource<DataResponse<ScholarshipAwarded>>('scholarshipsAwarded', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getScholarshipsPayments(id: string): Observable<DataResponse<ScholarshipPayment>> {
        return this.getResource<DataResponse<ScholarshipPayment>>('scholarshipsPayments', {params: {id}, query: {start: '1900-01-01', limit: 20}, handleError: true});
    }

    @cacheable()
    public getScholarshipPaymentsPages(url: string): Observable<DataResponse<ScholarshipPayment>> {
        return this.getResourceByUrl<DataResponse<ScholarshipPayment>>(url, true);
    }

    @cacheable()
    public getScholarshipsApplications(id: string): Observable<ScholarshipApplication[]> {
        return this.getResource<DataResponse<ScholarshipApplication>>('scholarshipsApplications', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
                catchError(this.returnNullIf404Error<ScholarshipApplication[]>)
            );
    }

    @cacheable()
    public getScholarshipsApplicationDetails(id: string, applicationId: string): Observable<ScholarshipApplicationDetail> {
        return this.getResource<ScholarshipApplicationDetail>('scholarshipsApplicationDetails', {params: {id, applicationId}, handleError: true})
            .pipe(
                map(result => result),
            );
    }

    @cacheable()
    public getThesisSubmissions(id: string): Observable<ThesisSubmission[]> {
        return this.getResource<DataResponse<ThesisSubmission>>('thesisSubmissions', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getApplicationQuestions(id: string, appNo: string): Observable<StudentApplicationQuestion[]> {
        return this.getResource<DataResponse<StudentApplicationQuestion>>('applicationQuestions', {params: {id, appNo}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    @cacheable()
    public getMilestones(id: string): Observable<Milestone[]> {
        return this.getResource<DataResponse<Milestone>>('milestones', {params: {id}, handleError: true})
            .pipe(
                map(result => result.data),
            );
    }

    private returnNullIf404Error<Type>(err): Observable<Type> {
        if (err.status == 404) {
            return of(null);
        } else {
            throw err;
        }
    }
}


export const StudentServiceProvider={ provide: StudentService, useClass: StudentService };
