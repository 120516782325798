import { UoaErrorsConfig } from '@uoa/error-pages';

export class AppErrorsConfig extends UoaErrorsConfig {
    constructor() {
        super();

        const getErrorPageContent = (code?: number) => {
            let title: string;
            let content: string;

            switch (code) {
                case 403:
                    title = 'Access Restricted';
                    content = `
                        <p>Sorry, You do not have permission to view this page. Please ensure that you are logged in and try again.</p>
                        <p>
                            If you believe your team would benefit from access, please complete the
                            <a href="https://uoaprod.service-now.com/sp" target="_blank">IT Portal</a>
                            item relating to CPP and note that you are requesting access for your team and why.
                        </p>
                        <p>
                            Contact the Staff Service Centre: Phone: 
                            <a href="tel:86000" target="_blank">86000 (within New Zealand)</a> or
                            <a href="tel:+64 9 923 6000" target="_blank">+64 9 923 6000 (outside New Zealand)</a>
                        </p>
                    `;
                    break;
                default:
                    title = 'Unexpected Error';
                    content = `
                        <p>
                            Sorry, we seem to have encountered an unexpected error. If you require help, please call 
                            the Staff Service Centre: Phone: 
                            <a href="tel:86000" target="_blank">86000 (within New Zealand)</a> or
                            <a href="tel:+64 9 923 6000" target="_blank">+64 9 923 6000 (outside New Zealand)</a>
                        </p>
                    `;
                    break;
            }

            return {
                title: title,
                content: content
            };
        };

        this.ErrorPageContent['ErrorCode403'] = getErrorPageContent(403);
        this.ErrorPageContent['ErrorCodeDefault'] = getErrorPageContent();
    }
}

export const AppErrorsConfigProvider={ provide: UoaErrorsConfig, useClass: AppErrorsConfig };