<app-field-list classList="margin-0">
    <ion-row class="fields-two-column-layout">
        <ion-col size="6">
            <app-field-value label="Class Title">{{ concession.class.description | default }}</app-field-value>
            <app-field-value label="Course">{{ concession.class.course.code | default }}</app-field-value>
            <app-field-value label="Course Points">{{ concession.coursePoints | default }}</app-field-value>
            <app-field-value label="Subject">{{ concession.class.subject.description | default }}</app-field-value>
        </ion-col>
        <ion-col size="6">
            <app-field-value label="Class Number">{{ concession.class.code | default }}</app-field-value>
            <app-field-value label="Class Component">{{ concession.class.component.description | default }}</app-field-value>
            <app-field-value label="Class Section">{{ concession.class.section | default }}</app-field-value>
            <app-field-value label="Starts On">{{ concession.class.start | shortDate | default }}</app-field-value>
        </ion-col>
    </ion-row>
</app-field-list>

<div class="margin-top-l">
    <app-field-list>
        <app-field-value label="Class Notes">
            <ng-container *ngIf="concession.class.notes?.length > 0; else noNotes">
                <p *ngFor="let note of concession.class.notes" [innerHtml]="note.note"></p>
            </ng-container>
            <ng-template #noNotes>
                No data
            </ng-template>
        </app-field-value>
    </app-field-list>
</div>

<h5>Concession Request Reason</h5>

<div class="margin-top-l">
    <app-field-list>
        <app-field-value label="Request Reason">{{ concession.requestReason?.description | default }}</app-field-value>
        <app-field-value label="Enrolment Status Reason">{{ concessionStatusReason() | default }}</app-field-value>
        <app-field-value label="Student Comments">{{ concession.studentComments | default }}</app-field-value>
    </app-field-list>

    <h5>Concession Request Details</h5>

    <app-field-value label="Faculty/Dept Comments">{{ concession.internalComments | default }}</app-field-value>
    <ng-container *ngIf="concession.comments?.length > 0; else noComments">
        <ion-label class="label">Student Facing Comments:</ion-label>
        <ion-grid class="table ion-padding-top">
            <div class="table__thead">
                <ion-row>
                    <ion-col size="2" class="ion-text-center">
                        Date
                    </ion-col>
                    <ion-col>
                        Comment
                    </ion-col>
                </ion-row>
            </div>
            <div class="table__tbody">
                <ng-container *ngFor="let comment of concession.comments">
                    <ion-row>
                        <ion-col size="2" class="ion-text-left">
                            {{ comment.createdOn | date:'dd/MM/yy h:mm a'}}
                        </ion-col>
                        <ion-col>{{ comment.comment }}</ion-col>
                    </ion-row>
                </ng-container>
            </div>
        </ion-grid>
    </ng-container>
    <ng-template #noComments>
        <app-field-value label="Student Facing Comments">{{ null | default }}</app-field-value>
    </ng-template>

</div>