<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="close()">
        <app-icon name="close" icon-style="filled" size="medium"></app-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary" class="padding-right-l padding-left-l">
      <div class="d-flex ion-justify-content-between ion-align-self-center padding-right-l padding-left-l">Application
        Questions</div>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <ng-container *ngIf="applicationQuestions?.length > 0; else noData">
      <ng-container *ngFor="let question of applicationQuestions">
        <div [attr.data-level]="question.dataLevel" class="ion-margin-bottom">
          <div class="label">
            {{ question.description }} -
            <span class="question-span" [innerHTML]="question.question"></span>
          </div>
          <div *ngIf="question.responseText">
            {{ question.responseText }}
          </div>
          <div *ngIf="question.multipleAnswerResponse">
            <ng-container *ngFor="let response of question.multipleAnswerResponse">
              <ul>
                <li>
                  <span class="label">{{ response.heading }}:</span>
                  {{ response.response }}
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="padding-right-l padding-left-l" *ngIf="!isLoading">
        <p class="header__notfound">No data to display</p>
      </div>
    </ng-template>
  </app-dataload-spinner>

  <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</ion-content>