import { Component, Input } from '@angular/core';
import { ThesisSubmission } from '@domain/models';

@Component({
  selector: 'app-thesis-submissions',
  templateUrl: './thesis-submissions.component.html',
  styleUrl: './thesis-submissions.component.scss'
})
export class ThesisSubmissionsComponent {
  openPanels = new Set<number>();

  @Input() submissions: ThesisSubmission[];

  handleToggleDetails(index: number) {
    if (!this.openPanels.has(index)) {
      this.openPanels.add(index);
    } else {
      this.openPanels.delete(index);
    }
  }

  isHideDetails(index: number) {
    return this.openPanels.has(index) ?? false;
  }
}
