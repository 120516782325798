import { Inject, Injectable } from '@angular/core';
import {
    AffiliationsViewComponent,
    NotAvailableComponent,
    VisaViewComponent,
    ServiceIndicatorsViewComponent,
    ExternalTestsViewComponent,
    PriorEducationViewComponent,
    SubmittedApplicationsViewComponent,
    ChecklistsViewComponent,
    EnglishProficiencyViewComponent,
    CommentsChecklistsViewComponent,
    ResidencyViewComponent,
    GroupsViewComponent,
    UnsubmittedApplicationsViewComponent,
    StudentProgressViewComponent,
    ApplicationCommentsViewComponent,
    PriorityGroupsViewComponent,
    StudyLinkViewComponent,
    StudyLinkLoanViewComponent,
    TuitionFeesViewComponent,
    ConcessionsViewComponent,
    ProgrammeCoursesViewComponent,
    ThirdPartyInfoViewComponent,
    TranscriptViewComponent,
    CommunicationsViewComponent,
    PersonalDetailsViewComponent,
    ExamsViewComponent,
    TaxInvoiceViewComponent,
    GraduationApplicationsViewComponent,
    ResearchInformationViewComponent,
    DegreesViewComponent,
    CourseCreditViewComponent,
    EnrolmentCartViewComponent,
    ScholarshipsHistoryViewComponent,
    ScholarshipPaymentsViewComponent,
    ScholarshipApplicationsViewComponent, ThesisSubmissionsViewComponent,
} from '../components';
import { PrimaryMenuItem, ViewMenuItem } from '../model/menu';
import { APP_CONFIG } from '@app/core/providers';
import { AppConfiguration } from '@app/domain/models';
import { clone } from 'ramda';
import { AuthorisationService } from '@app/core/services';
import { MenuItemId } from '@app/core/services/authorisation';

@Injectable()
export class PersonMenuService {
    constructor(@Inject(APP_CONFIG) private configuration: AppConfiguration, private auth: AuthorisationService) {

    }

    private get advisementLink() {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.advisement}?EMPLID={id}`;
    }

    private get grcLink() {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.graduationRequirements}?EMPLID={id}`;
    }

    private get unofficialTranscriptLink() {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.unofficialTranscript}?Page=SA_REPORT_RESULTS&INSTITUTION=UOFAK&EMPLID={id}&TSCRPT_TYPE=UNOFF`;
    }

    private get classSearchLink() {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.classSearch}?PAGE=SSR_CLSRCH_ENTRY&Folder=MYFAVORITES`;
    }

    private async isViewable(view: ViewMenuItem): Promise<boolean> {
        return (this.configuration.ui?.showUnavailableMenus || view.component !== NotAvailableComponent)
            && await this.auth.hasPermission(view.id);
    }

    /**
     * Get a cloned copy of the menu heirarchy. The consumer is free to modify the menu without affecting the global definitions.
     *
     * @returns Top level menu with children nodes
     */
    async getMenuItems(): Promise<PrimaryMenuItem[]> {
        const menus: PrimaryMenuItem[] = clone(this.getPrimaryMenu());

        // Remove submenus that the user doesn't have permission
        for await (const elem of menus) {
            const views = await Promise.all(elem.views.map(async (view) => await this.isViewable(view) ? view : null));
            elem.views = views.filter(view => view !== null);
        }

        // Only return top-level menus which have at least one view
        return menus
            .filter(m => m.views.length > 0);
    }

    private getPrimaryMenu(): PrimaryMenuItem[] {
        return [
            {
                id: MenuItemId.PERSON,
                name: 'Personal Profile',
                title: 'View student personal details',
                type: 'menu',
                views: [
                    {
                        id: MenuItemId.PERSONAL,
                        component: PersonalDetailsViewComponent,
                        title: 'Personal details',
                        name: 'Personal Details',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.AFFILIATIONS,
                        component: AffiliationsViewComponent,
                        title: 'Affiliations',
                        name: 'Affiliations',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.SERVICE_INDICATORS,
                        component: ServiceIndicatorsViewComponent,
                        title: 'Service indicators',
                        name: 'Service Indicators',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.RESIDENCY,
                        component: ResidencyViewComponent,
                        title: 'Residency Data',
                        name: 'Residency Data',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.STUDENT_GROUPS,
                        component: GroupsViewComponent,
                        title: 'Student Groups',
                        name: 'Student Groups',
                        type: 'component'
                    }
                ]
            },
            {
                id: MenuItemId.APPLICANT,
                name: 'Applications',
                type: 'menu',
                title: 'View students submitted, unsubmitted applications and related information',
                views: [
                    {
                        id: MenuItemId.PRIORITY_GROUPS,
                        component: PriorityGroupsViewComponent,
                        title: 'Priority groups',
                        name: 'Priority Groups',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.UNSUBMITTED_APPLICATIONS,
                        component: UnsubmittedApplicationsViewComponent,
                        title: 'Unsubmitted Applications',
                        name: 'Unsubmitted Applications',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.SUBMITTED_APPLICATIONS,
                        component: SubmittedApplicationsViewComponent,
                        title: 'Submitted Applications',
                        name: 'Submitted Applications',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.CHECKLISTS,
                        component: ChecklistsViewComponent,
                        title: 'Checklists',
                        name: 'Checklists',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.ENTRANCE_QUALIFICATIONS,
                        component: ApplicationCommentsViewComponent,
                        title: 'Entrance Qual and Comments',
                        name: 'Entrance Qual and Comments',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.PRIOR_EDUCATION,
                        component: PriorEducationViewComponent,
                        title: 'Prior Education',
                        name: 'Prior Education',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.SCHOOL_RESULTS,
                        component: ExternalTestsViewComponent,
                        title: 'NZ Schools Results for NCEA and other accredited exams',
                        name: 'NZ Schools Results',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.ENGLISH_LANGUAGE,
                        component: EnglishProficiencyViewComponent,
                        title: 'English Language proficiency and External assessments',
                        name: 'English Language',
                        type: 'component'
                    }
                ]
            },
            {
                id: MenuItemId.STUDENT,
                name: 'Student Profile',
                type: 'menu',
                title: 'View student academic information',
                views: [
                    {
                        id: MenuItemId.ENROLMENT,
                        component: EnrolmentCartViewComponent,
                        title: 'Enrolment Cart',
                        name: 'Enrolment Cart',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.PROGRAMME,
                        component: ProgrammeCoursesViewComponent,
                        title: 'Programmes and Courses',
                        name: 'Programmes and Courses',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.RESEARCH,
                        component: ResearchInformationViewComponent,
                        title: 'Research Information',
                        name: 'Research Information',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.CONCESSIONS,
                        component: ConcessionsViewComponent,
                        title: 'Concessions',
                        name: 'Concessions',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.PROGRESS,
                        component: StudentProgressViewComponent,
                        title: 'Student Progress',
                        name: 'Student Progress',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.CREDIT,
                        component: CourseCreditViewComponent,
                        title: 'Credit',
                        name: 'Credit',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.OFFICIAL_DOC_REQUEST,
                        component: TranscriptViewComponent,
                        title: 'Official Doc Request',
                        name: 'Official Doc Request',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.VISAS_INSURANCE,
                        component: VisaViewComponent,
                        title: 'Visas and Insurance',
                        name: 'Visas and Insurance',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.COMMENTS_CHECKLISTS,
                        component: CommentsChecklistsViewComponent,
                        title: 'Comments and Checklists',
                        name: 'Comments and Checklists',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.CS9_ADVISEMENTS,
                        title: 'View Advisements in CS9',
                        name: 'CS9 Advisements',
                        url: this.advisementLink,
                        target: '_blank',
                        type: 'button'
                    },
                    {
                        id: MenuItemId.CS9_GRADUATION_REQUIREMENTS,
                        title: 'View Graduation requirements in CS9',
                        name: 'CS9 GRC',
                        url: this.grcLink,
                        target: '_blank',
                        type: 'button'
                    },
                    {
                        id: MenuItemId.CS9_CLASS_SEARCH,
                        title: 'View class search in CS9',
                        name: 'CS9 Class Search',
                        url: this.classSearchLink,
                        target: '_blank',
                        type: 'button'
                    },
                    {
                        id: MenuItemId.CS9_UNOFFICIAL_TRANSCRIPT,
                        title: 'View unofficial transcript in CS9',
                        name: 'Unofficial Transcript',
                        url: this.unofficialTranscriptLink,
                        target: '_blank',
                        type: 'button'
                    }
                ]
            },
            {
                id: MenuItemId.FEES,
                name: 'Fees',
                type: 'menu',
                title: 'View student financial information',
                views: [
                    {
                        id: MenuItemId.TRANSACTION_FEES,
                        component: TuitionFeesViewComponent,
                        title: 'Transactions',
                        name: 'Transactions',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.STUDYLINK_STATUS,
                        component: StudyLinkViewComponent,
                        title: 'StudyLink Status',
                        name: 'StudyLink Status',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.LOAN_DETAILS,
                        component: StudyLinkLoanViewComponent,
                        title: 'Loan Details',
                        name: 'Loan Details',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.INVOICES,
                        component: TaxInvoiceViewComponent,
                        title: 'Tax Invoice',
                        name: 'Tax Invoice',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.THIRD_PARTY_INFO,
                        component: ThirdPartyInfoViewComponent,
                        title: 'Third party info',
                        name: 'Third Party Info',
                        type: 'component'
                    },
                ]
            },
            {
                id: MenuItemId.EXAMS,
                name: 'Exams and Assessments',
                type: 'menu',
                title: 'View student exam and assessment information',
                views: [
                    {
                        id: MenuItemId.EXAM_DETAILS,
                        component: ExamsViewComponent,
                        title: 'Exam details',
                        name: 'Exam Details',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.ASSESSMENTS,
                        component: NotAvailableComponent,
                        title: 'Assessments',
                        name: 'Assessments',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.THESIS_SUBMISSIONS,
                        component: ThesisSubmissionsViewComponent,
                        title: 'Thesis Submissions',
                        name: 'Thesis Submissions',
                        type: 'component'
                    },
                ]
            },
            {
                id: MenuItemId.GRADUATION,
                name: 'Graduation',
                type: 'menu',
                title: 'View student graduation information',
                views: [
                    {
                        id: MenuItemId.GRADUATION_APPLICATIONS,
                        component: GraduationApplicationsViewComponent,
                        title: 'Graduation Event Details',
                        name: 'Graduation Event Details',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.DEGREES,
                        component: DegreesViewComponent,
                        title: 'Degrees Conferred',
                        name: 'Degrees Conferred',
                        type: 'component'
                    },
                ]
            },
            {
                id: MenuItemId.SCHOLARSHIPS,
                name: 'Scholarships',
                type: 'menu',
                title: 'View unsubmitted and submitted scholarship information',
                views: [
                    {
                        id: MenuItemId.SCHOLARSHIPS_APPLICATIONS,
                        component: ScholarshipApplicationsViewComponent,
                        title: 'Scholarship Applications',
                        name: 'Scholarship Applications',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.SCHOLARSHIPS_HISTORY,
                        component: ScholarshipsHistoryViewComponent,
                        title: 'Scholarships History',
                        name: 'Scholarships History',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.SCHOLARSHIPS_PAYMENTS,
                        component: ScholarshipPaymentsViewComponent,
                        title: 'Scholarship Payments',
                        name: 'Scholarship Payments',
                        type: 'component'
                    }
                ]
            },
            {
                id: MenuItemId.COMMUNICATIONS,
                name: 'Communications',
                type: 'menu',
                title: 'View student inbound and outbound communication history',
                views: [
                    {
                        id: MenuItemId.COMMUNICATIONS_VIEW,
                        component: CommunicationsViewComponent,
                        title: 'Communications',
                        name: 'Communications',
                        type: 'component'
                    },
                    {
                        id: MenuItemId.COMMUNICATIONS_HISTORY,
                        component: NotAvailableComponent,
                        title: 'Communications history',
                        name: 'History',
                        type: 'component'
                    }
                ]
            }
        ] as const;
    }
}

export const PersonMenuServiceProvider={ provide: PersonMenuService };