<ion-grid class="ion-no-padding">
    <ion-row>
        <ion-col class="ion-no-padding" size="6">
            <app-field-list classList="margin-0">
                <app-field-value label="Request ID">{{ transcript.requestId | default }}</app-field-value>
                <app-field-value label="Request Time">{{ requestTime | default }}</app-field-value>
            </app-field-list>
        </ion-col>
        <ion-col class="ion-no-padding" size="6">
            <app-field-list classList="margin-0">
                <app-field-value label="Due Date">{{ transcript.dueOn | shortDate | default }}</app-field-value>
                <app-field-value label="Request Age">{{ requestAge || 0 }} day/s</app-field-value>
            </app-field-list>
        </ion-col>
    </ion-row>
</ion-grid>

<app-field-list>
    <app-field-value label="Document Type">{{ transcript?.documentType?.description | default }}</app-field-value>
    <app-field-value label="Delivery Method">{{ transcript.deliveryMethod.description | default }}</app-field-value>
</app-field-list>

<app-field-list>
    <app-field-value label="Last Updated Date/Time">{{ updateTime | default }}</app-field-value>
</app-field-list>

<app-field-list>
    <app-field-value label="Upload Comments">{{ transcript.comments | default }}</app-field-value>
</app-field-list>

<app-field-list>
    <app-field-value label="Date Certified">{{ transcript.certifiedOn | shortDate | default }}</app-field-value>
</app-field-list>

<app-field-list classList="margin-0">
    <app-field-value label="Staff Comments">{{ transcript.staffComments | default }}</app-field-value>
</app-field-list>