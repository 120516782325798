import { Component, Input } from '@angular/core';
import { Research, ResearchAdvisor } from '@domain/models';
import { ResearchInformationModalComponent } from '@modules/person/components';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-research-information-details',
  templateUrl: './research-information-details.component.html',
  styleUrl: './research-information-details.component.scss'
})
export class ResearchInformationDetailsComponent {
    @Input() details: Research;
    @Input() highlight: boolean;

    constructor(private modalCtrl: ModalController) {
    }

    async openModal(advisor: ResearchAdvisor) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                advisor: advisor,
                research: this.details,
            },
            cssClass: 'research-information-modal',
            component: ResearchInformationModalComponent,
        });

        void modal.present();
    }
}
