<div [class]="highlight ? 'row-white':'row-grey'">
    <app-field-list classList="margin-0">
        <ng-container *ngIf="checklist.application">
            <app-field-value label="Admit Term">{{ checklist.application.term?.description | default }}</app-field-value>
            <app-field-value label="Application No.">
                <a [routerLink]="applicationLink" [queryParams]="{applicationNumber: checklist.application.applicationNumber}">
                    {{ checklist.application.applicationNumber | default }}
                </a>
            </app-field-value>
            <app-field-value label="Programme">{{ checklist.application.programme?.description | default }}</app-field-value>
            <app-field-value label="Application Status">{{ checklist.application.status?.description | default }}</app-field-value>
        </ng-container>
        <app-field-value label="Staff Comments">
            <app-text-truncate [text]="checklist.comment" [modalConfig]="openChecklistModal(checklist)"></app-text-truncate>
        </app-field-value>
    </app-field-list>

    <ion-item-group *ngIf="checklist.items?.length > 0">
        <ion-item>
            <ion-label>
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <strong>Item</strong>
                        </ion-col>
                        <ion-col size="3">
                            <strong>Status</strong>
                        </ion-col>
                        <ion-col size="2">
                            <strong>Status Date</strong>
                        </ion-col>
                        <ion-col size="1"></ion-col>
                    </ion-row>
                </ion-grid>
            </ion-label>
        </ion-item>
        <ion-item lines="none" *ngFor="let item of checklist.items">
            <ion-label>
                <ion-grid>
                    <ion-row class="ion-align-items-center">
                        <ion-col>{{ item.type.description | default }}</ion-col>
                        <ion-col size="3">{{ item.status.description | default }}</ion-col>
                        <ion-col size="2">{{ item.statusChangedOn | shortDate | default }}</ion-col>
                        <ion-col size="1">
                            <ion-button (click)="openItemModal(item)" fill="clear"
                                class="ion-no-padding ion-no-margin">
                                <app-icon name="info" size="large" icon-style="filled" colour="primary" slot="icon-only"></app-icon>
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-label>
        </ion-item>
    </ion-item-group>
</div>