import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Comment, EntranceQualification, ServiceError } from '@app/domain/models';
import { PersonViewComponent } from '../../model/person-view.component';
import { StudentAdapter } from '@app/core/adapters';
import { sortByProp } from '@app/util';

@Component({
  templateUrl: './application-comments-view.component.html',
  styleUrl: './application-comments-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApplicationCommentsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    comments: Comment[];
    entranceQualification: EntranceQualification;

    openPanels = new Set<number>();

    isHideDetails(checklistId: number) {
        return this.openPanels.has(checklistId) ?? false;
    }

    handleToggleDetails(checklistId: number) {
        if (!this.openPanels.has(checklistId)) {
            this.openPanels.add(checklistId);
        } else {
            this.openPanels.delete(checklistId);
        }
    }

    render() {
        this.load();
    }

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getApplicationsEntranceQualAndComments(super.identity.id)
            .subscribe( {
                next: (entranceComments) => {
                    this.isLoading = false;
                    this.comments = sortByProp('descend', entranceComments.comments, 'commentDate');

                    if (entranceComments.entranceQualification?.embargoed) {
                        this.entranceQualification = {};
                    } else {
                        this.entranceQualification = entranceComments.entranceQualification;
                    }
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isError = true;
                    this.isLoading = false;
                }
        });
    }
}
