<ion-grid *ngIf="milestones?.length > 0" class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="6">Milestone</ion-col>
            <ion-col size="4" class="ion-text-center">Is Completed?</ion-col>
            <ion-col size="2" class="ion-text-center">Details</ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">
        <ng-container *ngFor="let milestone of milestones; even as isEven; index as i">
            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                <ion-col size="6">{{ milestone.description | default }}</ion-col>
                <ion-col size="4" class="ion-text-center">{{ milestone.isComplete | yesNo | default }}</ion-col>
                <ion-col size="2" class="ion-text-center">
                    <app-details-button [id]="i" (toggled)="handleToggleDetails(i)">
                    </app-details-button>
                </ion-col>
            </ion-row>
            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isShowDetails(i)"
                class="more-details">
                <ion-col size="12">
                    <ion-row class="fields-2-columns">
                        <ion-col size="6" class="ion-no-border">
                            <app-field-list>
                                <app-field-value label="Title" labelSize="6" valSize="6">
                                    {{ milestone?.title | default }}
                                </app-field-value>
                                <app-field-value label="Milestone Number" labelSize="6" valSize="6">
                                    {{ milestone?.milestoneNumber | default }}
                                </app-field-value>
                                <app-field-value label="Transcript Level" labelSize="6" valSize="6">
                                    {{ milestone?.transcriptLevel?.description | default }}
                                </app-field-value>
                                <app-field-value label="Date Required" labelSize="6" valSize="6">
                                    {{ milestone?.dateRequired | date: 'dd/MM/YYYY' | default }}
                                </app-field-value>
                                 <app-field-value label="Attempts Allowed" labelSize="6" valSize="6">
                                    {{ milestone?.attemptsAllowed | default }}
                                </app-field-value>
                            </app-field-list>
                        </ion-col>
                        <ion-col size="6" class="ion-no-border">
                            <app-field-list>
                                <app-field-value label="Committee Advised" labelSize="6" valSize="6">
                                    {{ milestone?.committeeAdvised | yesNo | default }}
                                </app-field-value>
                                <app-field-value label="Print Milestone Detail" labelSize="6" valSize="6">
                                    {{ milestone?.printMilestoneDetail?.description | default }}
                                </app-field-value>
                                <app-field-value label="Comments" labelSize="6" valSize="6">
                                    {{ milestone?.comments | default }}
                                </app-field-value>
                                <app-field-value label="Hide Comments" labelSize="6" valSize="6">
                                    {{ milestone?.hideComments | yesNo | default }}
                                </app-field-value>
                                <app-field-value label="Term Required" labelSize="6" valSize="6">
                                    {{ milestone?.termRequired?.description | default }}
                                </app-field-value>
                            </app-field-list>
                        </ion-col>
                    </ion-row>

                    <ion-grid *ngIf="milestone?.attempts?.length > 0" class="table ion-no-padding">
                        <div class="table__thead">
                            <ion-row>
                                <ion-col size="3">Attempt</ion-col>
                                <ion-col size="3" class="ion-text-center">Attempted On</ion-col>
                                <ion-col size="6" class="ion-text-center">Grade</ion-col>
                            </ion-row>
                        </div>

                        <ng-container *ngFor="let attempt of milestone.attempts; even as isEvenAttempt">
                            <div class="table__tbody">
                                <ion-row [ngClass]="!isEvenAttempt ? 'row-grey':'row-white'">
                                    <ion-col size="3">
                                        {{ attempt.attemptNumber | default }}
                                    </ion-col>
                                    <ion-col size="3">
                                        {{ attempt.attemptedOn | date: 'dd/MM/YYYY' | default }}
                                    </ion-col>
                                    <ion-col size="6">
                                        {{ attempt.grade?.description | default }}
                                    </ion-col>
                                </ion-row>
                            </div>
                        </ng-container>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ng-container>
    </div>
</ion-grid>