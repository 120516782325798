import { CS9PathsConfiguration, ResourcePathsConfiguration } from '@domain/models/app-configuration';

export const ResourcePaths: ResourcePathsConfiguration = {
    affiliations: 'persons/{id}/affiliations',
    checklist: 'students/{id}/check-lists',
    comments: 'students/{id}/comments',
    externalTests: 'students/{id}/external-tests',
    externalTestResults: 'students/{id}/external-tests/{testId}/results',
    identity: 'persons/{id}',
    search: 'search',
    serviceIndicators: 'students/{id}/service-indicators',
    visas: 'students/{id}/visas',
    educationEntrance: 'students/{id}/education/entrance',
    educationBackground: 'students/{id}/education/background',
    educationSecondary: 'students/{id}/education/secondary',
    educationTertiary: 'students/{id}/education/tertiary',
    educationFoundation: 'students/{id}/education/foundation',
    educationEnglish: 'students/{id}/education/english',
    unsubmittedApplications: 'persons/{id}/applications/unsubmitted',
    submittedApplications: 'students/{id}/applications',
    residency: 'students/{id}/residency',
    groups: 'students/{id}/groups',
    insurance: 'students/{id}/insurance',
    aelr: 'students/{id}/aelr',
    academicStanding: 'students/{id}/standing',
    priorityGroups: 'students/{id}/priority-groups',
    researchApplications: 'students/{id}/applications/research',
    studyLink: 'students/{id}/study-link',
    studyLinkLoans: 'students/{id}/study-link/loans',
    tuitionFees: 'students/{id}/tuition-fees',
    concessions: 'students/{id}/concessions',
    delna: 'students/{id}/delna',
    programmes: 'students/{id}/programmes',
    enrolments: 'students/{id}/enrolments',
    thirdPartyContracts: 'students/{id}/third-party-contracts',
    progress: 'students/{id}/progress',
    transcripts: 'students/{id}/transcripts',
    communications: 'students/{id}/communications',
    timetables: 'students/{id}/enrolments/timetables',
    termProgress: 'students/{id}/progress/term',
    exams: 'students/{id}/exams',
    referrals: 'students/{id}/applications/{appNo}/referrals',
    invoices: 'students/{id}/invoices',
    graduations: 'students/{id}/graduations',
    messages: 'messages',
    research: 'students/{id}/research',
    degrees: 'students/{id}/degrees',
    intendedCourses: 'students/{id}/applications/{appNo}/courses',
    credit: 'students/{id}/course-credit',
    enrolmentCart: 'students/{id}/enrolments/cart',
    scholarshipsAwarded: 'students/{id}/scholarships',
    embargo: 'embargo',
    scholarshipsPayments: 'students/{id}/scholarships/payments',
    scholarshipsApplications: 'scholarships/{id}/applications',
    scholarshipsApplicationDetails: 'scholarships/{id}/applications/{applicationId}/details',
    thesisSubmissions: 'students/{id}/thesis-submissions',
    applicationQuestions: 'students/{id}/applications/{appNo}/questions',
    milestones: 'students/{id}/milestones',
};

export const CS9Paths: CS9PathsConfiguration = {
    concessions: 'psp/ps/EMPLOYEE/SA/c/UOA_DEVELOPMENT.UOA_CCN_REVIEW_TBL.GBL',
    advisement: 'psp/ps_6/EMPLOYEE/SA/c/DEFINE_ACADEMIC_REQUIREMENTS.SAA_RPT_RQST.GBL',
    graduationRequirements: 'psp/ps_5/EMPLOYEE/SA/c/UOA_DEVELOPMENT.UOA_GRC_ST_INQ.GBL',
    taxInvoice: 'psp/ps/EMPLOYEE/SA/c/UOA_DEVELOPMENT.UOA_SF_INV_REPRINT.GBL',
    unofficialTranscript: 'psp/ps/EMPLOYEE/SA/c/MANAGE_ACADEMIC_RECORDS.TSCRPT_RQST.GBL',
    checklistManagement: 'psp/ps/EMPLOYEE/SA/c/MANAGE_CHECKLISTS.CHKLST_MANAGEMENT.GBL',
    fees: 'psp/ps/EMPLOYEE/SA/c/UOA_DEVELOPMENT.UOA_STMT_RUNCNTL.GBL',
    application: 'psp/ps/EMPLOYEE/SA/c/PROCESS_APPLICATIONS.ADM_APPL_MAINTNCE.GBL',
    programmePage: 'psp/ps/EMPLOYEE/SA/c/TRACK_STUDENT_CAREERS.ACAD_PLAN.GBL',
    classSearch: 'psp/ps/EMPLOYEE/SA/c/ESTABLISH_COURSES.CLASS_SEARCH.GBL'
};

export const Scopes = [
    'openid',
    'profile',
    'https://contact-centre.auckland.ac.nz/contact-centre-spa',
];