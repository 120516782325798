<ng-container *ngIf="checklist?.length > 0; else emptyChecklists">
    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2">
                    Admin. Function
                </ion-col>
                <ion-col size="4">
                    Checklists
                </ion-col>
                <ion-col size="2">
                    Status
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Status Date
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let checklist of checklist; even as isEven">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ checklist.adminFunction.code}}</ion-col>
                    <ion-col size="4">{{ checklist.type.description | default }}</ion-col>
                    <ion-col size="2">{{ checklist.status.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ checklist.statusChangedOn | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="checklist.id" (toggled)="handleToggleDetails(checklist.id)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(checklist.id)" class="more-details">
                    <ion-col size="12">
                        <app-checklist-details [checklist]="checklist" [highlight]="isEven" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </div>
    </ion-grid>
</ng-container>

<ng-template #emptyChecklists>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>