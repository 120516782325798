import { Component, Input, OnInit} from '@angular/core';
import {Milestone, ServiceError} from '@domain/models';
import { ModalController } from '@ionic/angular';
import { ProgrammeViewModel } from '@modules/person/components/programme-courses-view/programme-courses-view.model';
import { StudentAdapter } from '@core/adapters';

@Component({
  selector: 'app-milestones-modal',
  templateUrl: './milestones-modal.component.html',
  styleUrl: './milestones-modal.component.scss'
})
export class MilestonesModalComponent implements OnInit {
  isLoading = true;
  isError = false;
  message: string;
  milestones: Milestone[] = [];

  constructor(private modalCtrl: ModalController, private student: StudentAdapter) {}

  @Input() studentId: number;
  @Input() programme: ProgrammeViewModel;

  ngOnInit() {
      this.load();
  }

  load(): void {
      this.student.getMilestones(this.studentId)
          .subscribe({
              next: (result) => {
                  if (result) {
                    this.milestones = result.filter(milestone => milestone.programme.code === this.programme.programme.code);
                  }

                  this.isLoading = false;
              },
              error: (error) => {
                  if (error instanceof ServiceError) {
                      this.message = error.message;
                  }

                  this.isLoading = false;
                  this.isError = true;
              }
          });
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
