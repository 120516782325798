<ion-header class="ion-no-border" xmlns="http://www.w3.org/1999/html">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-padding" color="primary">
            Courses and Classes
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <ng-container *ngIf="courses?.length > 0">
            <ion-grid class="ion-no-padding margin-bottom-l">
                <ion-row class="ion-justify-content-between">
                    <ion-col size="auto">
                        <p class="color-dark-1 font-default bold margin-0">{{ programmeDesc }}</p>
                    </ion-col>
                    <ion-col size="auto">
                        <ion-button class="margin-0" target="_blank" [href]="programmePageLink" fill="outline" color="primary" size="small" shape="round">
                            CS9 Programme Page
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="auto">
                        <ion-item color="primary">
                            <ion-select [value]="defaultTerm" (ionChange)="onChangeTerm($event)" aria-label="Term" [interfaceOptions]="customPopoverOptions" interface="popover" placeholder="Select Term">
                                <ng-container *ngFor="let course of courses">
                                    <ion-select-option [value]="course.term">{{ course.term }}</ion-select-option>
                                </ng-container>
                            </ion-select>
                        </ion-item>
                    </ion-col>
                    <ion-col *ngIf="termGPA" offset="1" class="ion-align-self-center color-primary">
                        Term GPA: {{ termGPA }}
                    </ion-col>
                </ion-row>
            </ion-grid>

            <ion-grid class="table ion-no-padding margin-top-l">
                <div class="table__thead">
                    <ion-row>
                        <ion-col size="2">Subject</ion-col>
                        <ion-col size="4">Class</ion-col>
                        <ion-col size="2">Points</ion-col>
                        <ion-col size="2">Grade</ion-col>
                        <ion-col size="2" class="ion-text-center">Details</ion-col>
                    </ion-row>
                </div>
                <div class="table__tbody">

                    <ng-container *ngIf="courseList?.length > 0; else empty">
                        <ng-container *ngFor="let course of courseList; even as isEven; index as i">
                            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                                <ion-col size="2">{{ course.primaryClass.class.subject.code | default }} {{ course.primaryClass.class.catalogNumber }}</ion-col>
                                <ion-col size="4">{{ course.primaryClass.class.description | default }}</ion-col>
                                <ion-col size="2">{{ course.totalClassPoints | default }}</ion-col>
                                <ion-col size="2">{{ course.primaryClass.courseGradeAchieved || null | default }}</ion-col>
                                <ion-col size="2" class="ion-text-center">
                                    <app-details-button [id]="i" (toggled)="handleToggleDetails(i)" />
                                </ion-col>
                            </ion-row>
                            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                                <ion-col size="12">
                                    <app-course-details [course]="course" />
                                </ion-col>
                            </ion-row>
                        </ng-container>
                    </ng-container>
                    <ng-template #empty>
                        <ion-row>
                            <ion-col size="12" class="ion-text-center">
                                No data to display
                            </ion-col>
                        </ion-row>
                    </ng-template>

                </div>
            </ion-grid>
        </ng-container>
    </app-dataload-spinner>

    <div *ngIf="!isLoading && courseTerms?.length === 0">
        <p class="header__notfound margin-0">No data to display</p>
    </div>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</ion-content>