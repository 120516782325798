import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgrammeDetailsViewModel } from './programme-details-view.model';
import {ProgrammeViewModel} from '@modules/person/components/programme-courses-view/programme-courses-view.model';

@Component({
  selector: 'app-programme-details',
  templateUrl: './programme-details.component.html',
  styleUrl: './programme-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgrammeDetailsComponent {
    viewModel: ProgrammeDetailsViewModel;

    @Input() set programme(programme: ProgrammeViewModel) {
        const groups = programme.plans.filter(v => v.group).map(v => v.group.description);

        this.viewModel = {...programme, groups: groups };
    }

    get programme(): ProgrammeDetailsViewModel {
        return this.viewModel;
    }
}
