import { Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { StudentAdapter } from '@core/adapters';
import { Research, ServiceError } from '@app/domain/models';

@Component({
  selector: 'app-research-information-view',
  templateUrl: './research-information-view.component.html',
  styleUrl: './research-information-view.component.scss'
})
export class ResearchInformationViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    research: Research[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getResearchInfo(super.identity.id)
            .subscribe(
                {
                    next: (result) => {
                        this.research = result;
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isError = true;
                        this.isLoading = false;
                    },
                });
    }

    render() {
        this.load();
    }
}
