<div [class]="highlight ? 'row-white':'row-grey'">
    <app-field-list classList="margin-0">
        <app-field-value label="Category">{{ comment.category?.code| default }}</app-field-value>
        <app-field-value label="Category Description">{{ comment.category?.description | default }}</app-field-value>
    </app-field-list>
    <app-field-list>
        <app-field-value label="Comment"><span [innerHTML]="comment.comments"></span></app-field-value>
    </app-field-list>

    <app-accordian [header]="accordianHeader" *ngIf="showLinkedDetailsAccordian(comment)">
        <app-field-list classList="margin-0">
            <app-field-value label="Academic Career">{{ comment.career?.code | default }}</app-field-value>
            <app-field-value label="Application number" *ngIf="showApplicationNumber(comment)">
                <a [routerLink]="applicationLink" [queryParams]="{applicationNumber: comment.applicationNumber}">
                    {{ comment.applicationNumber | default }}
                </a>
            </app-field-value>
            <app-field-value label="Acad year" *ngIf="showAcademicYear(comment)">
                {{ comment.term?.academicYear | default }}
            </app-field-value>
        </app-field-list>
        <app-field-list classList="margin-bottom-0">
            <app-field-value label="Programme" *ngIf="showProgramCodeDescription(comment)">
                {{ comment.programme?.code | default }}
            </app-field-value>
            <app-field-value label="Programme description" *ngIf="showProgramCodeDescription(comment)">
                {{ comment.programme?.description | default }}
            </app-field-value>
        </app-field-list>
    </app-accordian>
</div>