<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2 class="margin-bottom-l">Submitted Applications</h2>
        <app-submitted-applications [submittedApplications]="submittedApplications" [selected]="selected"/>
    </app-dataload-spinner>
    
    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>


