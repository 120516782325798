import { Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ServiceError, ThesisSubmission } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { sortByProp } from '@app/util';

@Component({
  selector: 'app-thesis-submissions-view',
  templateUrl: './thesis-submissions-view.component.html',
  styleUrl: './thesis-submissions-view.component.scss'
})
export class ThesisSubmissionsViewComponent extends PersonViewComponent {
  isLoading = true;
  isError = false;
  message: string;
  thesis: ThesisSubmission[];

  constructor(private student: StudentAdapter) {
    super();
  }

  load(): void {
    this.isLoading = true;
    this.isError = false;

    this.student.getThesisSubmissions(super.identity.id)
        .subscribe({
          next: result => {
            this.thesis = sortByProp('descend', result, 'actualSubmitOn');
            this.isLoading = false;
          },

          error: (error) => {
            if (error instanceof ServiceError) {
              this.message = error.message;
            }
            this.isLoading = false;
            this.isError = true;
          },
        });
  }

  render(): void {
    this.load();
  }
}
