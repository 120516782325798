import { Component, Input } from '@angular/core';
import { Research, ResearchAdvisor } from '@domain/models';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-research-information-modal',
  templateUrl: './research-information-modal.component.html',
  styleUrl: './research-information-modal.component.scss'
})
export class ResearchInformationModalComponent {
    @Input() research: Research;
    @Input() advisor: ResearchAdvisor;

    constructor(private modalCtrl: ModalController) {
    }

    get plans() {
      if (!this.advisor?.planCode) {
        return [];
      }

      return this.research.plans.filter(pln => pln.plan.code === this.advisor.planCode);
    }

    close() {
        void this.modalCtrl.dismiss();
    }
}
