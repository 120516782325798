<div class="row-white">
    <app-field-list classList="margin-0">
        <app-field-value label="Applied to ELA">
            {{ englishLanguageEducation?.hasAppliedToAcademy | yesNo | default}}
        </app-field-value>
        <app-field-value label="Qualification">
            {{ englishLanguageEducation?.qualification?.description | default }}
        </app-field-value>
        <app-field-value label="Tested On">
            {{ englishLanguageEducation?.testedOn | shortDate | default }}
        </app-field-value>
        <app-field-value label="Reference">
            {{ englishLanguageEducation?.reference | default }}
        </app-field-value>
    </app-field-list>
</div>