<div class="thesis-details">
    <app-field-list classList="margin-0">
        <ion-row>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value labelSize="8" valSize="4" label="Intended Submission Date">
                    {{ submission.intendSubmitOn | shortDate | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Resubmit Due Date">
                    {{ submission.resubmitDueOn | shortDate | default }}
                </app-field-value>                   
                <app-field-value labelSize="8" valSize="4" label="Request Return of Thesis">
                    {{ submission.isThesisReturn | yesNo | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Thesis Return Due">
                    {{ submission.thesisReturnDueOn | shortDate | default }}
                </app-field-value>                
            </ion-col>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value labelSize="8" valSize="4" label="Actual Submission Date">
                    {{ submission.actualSubmitOn | shortDate | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Oral Exam Date">
                    {{ submission.oralExamOn | shortDate | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Degree">
                    {{ submission.degree?.description | default }}
                </app-field-value>          
                <app-field-value labelSize="8" valSize="4" label="Career No.">
                    {{ submission.careerNumber | default }}
                </app-field-value>                         
            </ion-col>
        </ion-row>
    </app-field-list>

    <app-field-list classList="margin-0">
        <ion-row>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value labelSize="8" valSize="4" label="Outcome">
                    {{ submission.outcome?.description | default }}
                </app-field-value>                
                <app-field-value labelSize="8" valSize="4" label="Is Completed">
                    {{ submission.isFinalOutcome | yesNo | default }}
                </app-field-value>
            </ion-col>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value labelSize="8" valSize="4" label="Outcome Approval Date">
                    {{ submission.outcomeApprovedOn | shortDate | default }}
                </app-field-value>
            </ion-col>
        </ion-row>
    </app-field-list>

    <app-field-list classList="margin-0" *ngIf="submission.comments">
        <app-field-value labelSize="4" valSize="8" label="Comments">
            {{ submission.comments | default }}
        </app-field-value>
    </app-field-list>    

    <app-field-list classList="margin-bottom-0">
        <ion-row>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value labelSize="8" valSize="4" label="Panel Type">
                    {{ submission.thesisPanelType?.description | default }}
                </app-field-value>
            </ion-col>       
            <ion-col class="ion-no-padding" size="6">
                <app-field-value labelSize="8" valSize="4" label="Recommended Panel Size">
                    {{ submission.recommendedPanelSize | default }}
                </app-field-value>
            </ion-col>
        </ion-row>
    </app-field-list>    
</div>

<!-- Thesis Formats -->
<app-accordian header="Thesis Formats" expanded="false" [highlight]="!highlight" *ngIf="formats?.length > 0">
    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="4">
                    Thesis Format
                </ion-col>
                <ion-col size="4">
                    Number of Copies
                </ion-col>
                <ion-col size="4">
                    Weighting (%)
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let format of formats; even as isEven">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="4">{{ format.format.description  | default }}</ion-col>
                    <ion-col size="4" class="ion-text-right">{{ format.copies | default }}</ion-col>
                    <ion-col size="4" class="ion-text-right">{{ format.weight | default }}%</ion-col>
                </ion-row>
            </ng-container>
        </div>
    </ion-grid>
</app-accordian>

<!-- Events -->
<app-accordian header="Events" expanded="false" [highlight]="!highlight" *ngIf="events?.length > 0">
    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2">
                    Event ID
                </ion-col>
                <ion-col size="4">
                    Description
                </ion-col>
                <ion-col size="2">
                    Approval Date
                </ion-col>
                <ion-col size="4">
                    Comment
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let event of events; even as isEven">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ event.eventNumber | default }}</ion-col>
                    <ion-col size="4">{{ event.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ event.approvedOn | shortDate | default }}</ion-col>
                    <ion-col size="4">{{ event.eventComments | default }}</ion-col>
                </ion-row>
            </ng-container>
        </div>
    </ion-grid>
</app-accordian>

