<app-field-list classList="margin-top-0">
    <ion-row>
        <ion-col class="ion-no-padding" size="6">
            <app-field-list>
                <app-field-value label="Academic Career">
                    {{ details.career.description | default }}
                </app-field-value>
                <app-field-value label="Career No.">
                    {{ details.careerNumber | default }}
                </app-field-value>    
                <app-field-value label="Department">
                    {{ details.department?.description | default }}
                </app-field-value>
                <app-field-value label="Programme Status">
                    {{ details.programmeStatus?.description | default}}
                </app-field-value>    
                <app-field-value label="Programme Action">
                    {{ details.programmeAction?.description | default}}
                </app-field-value>     
                <app-field-value label="Course Status">
                    {{ details.status?.description | default}}
                </app-field-value>       
                <app-field-value label="Faculty">
                    {{ details.programme.faculty.description | default}}
                </app-field-value>
            </app-field-list>            
        </ion-col>

        <ion-col class="ion-no-padding" size="6">
            <app-field-list>
                <app-field-value label="Earliest Submit">
                    {{ details.minimumSubmit | shortDate }}
                </app-field-value>
                <app-field-value label="Latest Submit">
                    {{ details.maximumSubmit | shortDate }}
                </app-field-value>    
                <app-field-value label="Reviewed On">
                    {{ details.reviewedOn | shortDate }}
                </app-field-value>
                <app-field-value label="Confirmed On">
                    {{ details.confirmedOn | shortDate}}
                </app-field-value>    
                <app-field-value label="Candidate Status">
                    {{ details.candidateStatus?.description| default}}
                </app-field-value>                  
            </app-field-list>  
        </ion-col>
    </ion-row>
</app-field-list>

<app-field-list classList="margin-top-0">
    <app-field-value label="Academic Plan(s)" labelSize="2" valSize="4">
        <ng-container *ngIf="details.plans?.length > 0; else noPlans">
            <ng-container *ngFor="let plan of details.plans">
                {{ plan.plan.description }} ({{ plan.plan.code }})<br/>
                <app-field-value label="Option">
                    {{ plan.option?.description | default }}
                </app-field-value>
                <app-field-value label="Points">
                    {{ plan.points?.description | default}}
                </app-field-value>
                <app-field-value label="Duration">
                    {{ plan.duration?.description | default}}
                </app-field-value>
            </ng-container>
        </ng-container>
        <ng-template #noPlans>
            No data
        </ng-template>
    </app-field-value>
</app-field-list>

<app-field-list>
    <app-field-value label="Topic" labelSize="2" valSize="8">{{ details.topic | default }}</app-field-value>
</app-field-list>

<ion-grid class="bare-table ion-no-padding" *ngIf="details.advisors?.length > 0">
    <app-field-value label="Supervisor Model">
        {{ details.supervisorModel?.description | default}}
    </app-field-value>

    <ion-row class="bare-table__thead margin-bottom-l">
        <ion-col class="bold">ID</ion-col>
        <ion-col class="bold">Name</ion-col>
        <ion-col class="bold">Workload</ion-col>
        <ion-col class="bold">Role</ion-col>
        <ion-col size="2" class="bold ion-text-center">Details</ion-col>
    </ion-row>
    <ion-row class="bare-table__tbody ion-align-self-start margin-bottom-m" *ngFor="let advisor of details.advisors">
        <ion-col>{{ advisor.id | default }}</ion-col>
        <ion-col>{{ advisor.name | default }}</ion-col>
        <ion-col>{{ advisor.percentage | default }}%</ion-col>
        <ion-col>{{ advisor.advisorRole.description | default }}</ion-col>
        <ion-col size="2" class="ion-text-center">
            <ion-button (click)="openModal(advisor)" fill="clear" class="ion-no-padding ion-no-margin">
                <app-icon name="info" slot="icon-only" size="large" colour="secondary"></app-icon>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>