import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import { ServiceError, StudentProgramme } from '@domain/models';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ProgrammeCoursesViewModel, ProgrammeViewModel } from './programme-courses-view.model';
import { groupBy } from 'ramda';

@Component({
  templateUrl: './programme-courses-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProgrammeCoursesViewComponent extends PersonViewComponent {
    programmes: ProgrammeCoursesViewModel;
    isLoading = true;
    isError = false;
    message: string;

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getStudentProgrammes(super.identity.id)
            .subscribe({
                next: result => {
                    this.programmes = this.buildViewModel(result);
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    buildViewModel(result: StudentProgramme[]): ProgrammeCoursesViewModel {
        const programmeGroup = result.map((programme: StudentProgramme) => {
            return { ...programme, studentId: super.identity.id };
        });

        return groupBy((v: ProgrammeViewModel) => {
            if (v.status.code === 'AC') {
                return 'active';
            }

            return 'inactive';
        }, programmeGroup);
    }
}
