<app-field-list classList="margin-0">
    <ion-row class="details">
        <ion-col class="ion-no-padding" size="6">
            <app-field-value labelClassList="margin-right-m" labelSize="auto" valSize="" label="Admin Function">
                {{ communication.adminFunction.description | default }}
            </app-field-value>
            <app-field-value labelClassList="margin-right-m" labelSize="auto" valSize="" label="Method">
                {{ communication.method.description | default }}
            </app-field-value>
            <app-field-value labelClassList="margin-right-m"  labelSize="auto" valSize="" label="To">
                {{ communication.emailTo | default }}
            </app-field-value>
            <app-field-value labelClassList="margin-right-m" labelSize="auto" valSize="" label="CC"  *ngIf="emailCc">
                <p class="margin-top-0 margin-bottom-m" *ngFor="let email of emailCc">
                    {{ email }}
                </p>
            </app-field-value>
        </ion-col>
        <ion-col class="ion-no-padding" size="6">
            <app-field-value labelClassList="margin-right-m" labelSize="auto" valSize="" label="Context">
                {{ communication.context.code | default }}
            </app-field-value>
            <app-field-value labelClassList="margin-right-m" labelSize="auto" valSize="" label="Communications" *ngIf="documentLink">
                <a [href]="documentLink" target="_blank">
                    View Communications
                </a>
            </app-field-value>
        </ion-col>
    </ion-row>

    <app-accordian header="Associated data" expanded="false" [highlight]="!highlight" *ngIf="hasLinkedData">
        <app-field-list classList="margin-0">
            <app-field-value label="Academic Career">
                {{ communication.academicProgramme?.career.description | default }}
            </app-field-value>
            <app-field-value label="Application number">
                {{ communication.applicationNumber | default }}
            </app-field-value>
            <app-field-value label="Academic Year">
                {{ communication.term?.academicYear | default }}
            </app-field-value>
        </app-field-list>
        <app-field-list classList="margin-bottom-0">
            <app-field-value label="Programme">
                {{ communication.academicProgramme?.code | default }}
            </app-field-value>
            <app-field-value label="Programme Description">
                {{ communication.academicProgramme?.description | default }}
            </app-field-value>
        </app-field-list>
    </app-accordian>
    <ion-row class="comms-completed" [ngClass]="!highlight ? 'row-white':'row-grey'" *ngIf="communication.isCompleted">
        <ion-col size="1">
            <app-boolean [value]="communication.isCompleted" />
        </ion-col>
        <ion-col offset="1" class="bold color-dark-1">Communication Completed</ion-col>
        <ion-col offset="1">
            <span class="bold color-dark-1 margin-right-l">Date Completed</span>
            <span class="color-dark-1">{{ communication.completedOn | shortDate | default }}</span>
        </ion-col>
    </ion-row>
</app-field-list>