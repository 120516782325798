<div [class]="highlight ? 'row-white':'row-grey'">
    <app-field-list classList="margin-top-0">
        <app-field-value *ngFor="let plan of application.programme.academicPlans; index as i"
            label="Academic Plan and Type {{i+1}}">
            {{ plan.description | default }}&nbsp; {{ plan.type }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.status)" label="Status">
            {{ application.status | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.statusMessage)" label="Status Message">
            <div [innerHTML]="application.statusMessage"></div>
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.admitType?.description)" label="Admit Type">
            {{ application.admitType?.description | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.programme?.isConjoint)" label="Is Conjoint">
            {{ application.programme?.isConjoint | yesNo | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.programme.academicCareer?.description)" label="Academic Career">
            {{ application.programme.academicCareer?.description | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.programme.campus?.description)" label="Campus">
            {{ application.programme.campus?.description | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application.admitTerm?.description)" label="Term">
            {{ application.admitTerm?.description | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application?.termSession)" label="Term Session">
            {{ application?.termSession | default }}
        </app-field-value>

        <app-field-value *ngIf="notBlank(application?.agent)" label="Agent">
            {{ application?.agent | default }}
        </app-field-value>

        <app-field-value label="Last Updated">
            {{ application.updateDate | default }}
        </app-field-value>

        <app-field-value label="Creation Date">
            {{ application.createDate | default }}
        </app-field-value>

        <app-field-value label="Last Page Visited">
            {{ application.lastVisitedPage | default }}
        </app-field-value>
    </app-field-list>

    <ion-item lines="none">
        <ion-label>
            <ion-grid>
                <ion-row>
                    <ion-col size="12" class="display-flex ion-justify-content-center">
                        <ion-button (click)="openAtsLink(application)" shape="round" fill="solid" color="secondary">
                            Go to ATS for more details
                            <app-icon name="arrow forward ios" colour="light" icon-style="filled" size="medium" slot="end"
                                class="font-default padding-top-2xs"></app-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-label>
    </ion-item>
</div>