import { Component, EventEmitter, Input, Output } from '@angular/core';
import { toBoolean } from '@app/util/booleans';
import { AccordionGroupCustomEvent } from '@ionic/core';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent {
    @Input() header: string;

    @Input({transform: toBoolean}) expanded = false;
    @Output() expandedChange = new EventEmitter<boolean>();

    @Input({transform: toBoolean}) highlight?: boolean;

    handleAccordionChangeEvent = (event: AccordionGroupCustomEvent) => {
      if (event.target.tagName == 'ION-ACCORDION-GROUP') {
        const selectedValue = event.detail.value;
        this.expanded = selectedValue == 'first';
        this.expandedChange.emit(this.expanded);
        event.stopPropagation();
      }
    };

    get expandedAccordian(): string {
      return this.expanded ? 'first' : undefined;
    }
}
