import { AfterContentInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Plan } from '@domain/models';
import { ModalController } from '@ionic/angular';
import { CoursesModalComponent, MilestonesModalComponent, PlansModalComponent } from '@modules/person/components';
import { ProgrammeViewModel } from '../programme-courses-view.model';
import { AuthorisationService } from '@app/core/services';
import { ObjectAttributeId } from '@app/core/services/authorisation';

type Career = { careerNumber: number; careerCode: string };

@Component({
  selector: 'app-programme-table',
  templateUrl: './programme-table.component.html',
  styleUrl: './programme-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgrammeTableComponent implements AfterContentInit, OnInit {
    openPanels = new Set<number>();
    allowCoursesButton: boolean = false;

    @Input() programmes: ProgrammeViewModel[];
    @Input() selected: Career;
    constructor(private modalCtrl: ModalController, private auth: AuthorisationService) {}

    async ngAfterContentInit(): Promise<void> {
        this.allowCoursesButton = await this.auth.hasPermission(ObjectAttributeId.PROGRAMME_COURSES_AND_CLASSES);
    }

    ngOnInit() {
        if (this.programmes?.length > 0) {
            const found = this.programmes.find(v =>
                v.programme.career.code === this.selected.careerCode && v.careerNumber === this.selected.careerNumber);
            const foundIndex = this.programmes.indexOf(found);

            this.handleToggleDetails(foundIndex);
        }
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    async openCourseModal(programme: ProgrammeViewModel) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                programme: programme,
            },
            component: CoursesModalComponent,
            cssClass: 'courses-modal'
        });

        void modal.present();
    }

    async openPlansModal(plans: Plan[]) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                plans: plans
            },
            component: PlansModalComponent,
            cssClass: 'plans-modal'
        });

        void modal.present();
    }

    async openMilestonesModal(studentId: number, programme: ProgrammeViewModel) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                studentId: studentId,
                programme: programme
            },
            component: MilestonesModalComponent,
            cssClass: 'milestones-modal'
        });

        void modal.present();
    }
}
