<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="2">
                Code
            </ion-col>
            <ion-col size="4">
                Description
            </ion-col>
            <ion-col size="2">
                Acad. Load
            </ion-col>
            <ion-col size="2">
                Status
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="programmes?.length > 0; else empty">
            <ng-container *ngFor="let programme of programmes; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ programme.programme.code | default }}</ion-col>
                    <ion-col size="4">{{ programme.programme.description | default }}</ion-col>
                    <ion-col size="2">{{ programme.academicLoad.description | default }}</ion-col>
                    <ion-col size="2">{{ programme.status.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-programme-details [programme]="programme" />
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)">
                    <ion-col size="12" class="ion-text-center">
                        <ion-button size="large" class="margin-right-l" (click)="openMilestonesModal(programme.studentId, programme)">
                            Milestones
                        </ion-button>
                        <ion-button [disabled]="programme.plans?.length === 0" size="large" (click)="openPlansModal(programme.plans)" class="margin-right-l">
                            Plans
                        </ion-button>
                        <ion-button *ngIf="allowCoursesButton" size="large" (click)="openCourseModal(programme)">
                            Courses and Classes
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #empty>
            <ion-row>
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>