<ng-container *ngIf="submissions?.length > 0; else noData">
    <ion-grid class="table ion-no-padding margin-top-l">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2">
                    Submitted
                </ion-col>
                <ion-col size="2">
                    Career
                </ion-col>
                <ion-col size="2">
                    Submission No.
                </ion-col>
                <ion-col size="4">
                   Thesis Title
                </ion-col>         
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">

            <ng-container *ngFor="let item of submissions; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ item.actualSubmitOn | shortDate | default }}</ion-col>
                    <ion-col size="2">{{ item.career.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ item.thesisSubmitNumber | default }}</ion-col>
                    <ion-col size="4">{{ item.thesisTitle | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)" />
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-thesis-submission-details [highlight]="isEven"  [submission]="item" />
                    </ion-col>
                </ion-row>
            </ng-container>

        </div>
    </ion-grid>
</ng-container>
<ng-template #noData>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>