import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';
import { ApplicationQuestionsViewModel } from './application-questions-view.model';
import { StudentAdapter } from '@core/adapters';
import { ServiceError, StudentApplicationQuestion } from '@app/domain/models';
import { zip } from 'ramda';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-application-questions',
  templateUrl: './application-questions.component.html',
  styleUrl: './application-questions.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ApplicationQuestionsComponent implements OnInit {
  isLoading = true;
  isError = false;
  message: string;

  @Input() application: SubmittedApplicationsViewModel;
  applicationQuestions: ApplicationQuestionsViewModel[] = null;

  constructor(private modalCtrl: ModalController, private studentAdapter: StudentAdapter) {
  }

  ngOnInit() {
    this.load();
  }

  public load(): void {
    this.studentAdapter.getApplicationQuestions(this.application.studentId, this.application.applicationNumber).subscribe({
      next: (result) => {
        this.applicationQuestions = this.mapToViewModel(result);
        this.isLoading = false;
        this.isError = false;
      },
      error: (error) => {
        if (error instanceof ServiceError) {
          this.message = error.message;
        }
        this.isLoading = false;
        this.isError = false;
      },
    });
  }

  close() {
    void this.modalCtrl.dismiss();
  }


  private mapToViewModel(applicationQuestions: StudentApplicationQuestion[]): ApplicationQuestionsViewModel[] {
    return applicationQuestions.map(question => {
      let responseText: string = null;
      let multipleAnswerResponse: {heading: string, response: string}[] = null;

      if (question?.headings?.length > 0) {
        multipleAnswerResponse = zip(question.headings, question.responses)
          .map((headingResponse => ({heading: headingResponse[0], response: headingResponse[1]})));
      } else {
        responseText = question?.responses[0];
      }

      return {
        description: question.description,
        dataLevel: question.level,
        question: question.question,
        responseText,
        multipleAnswerResponse
      };
    });
  }
}
